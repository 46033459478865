import { LitElement, css, html } from "lit";
import { customElement } from "lit/decorators.js";
import { boardBaseStyles } from "../board-base-styles";

@customElement("small-button")
export class SmallButton extends LitElement {

  static styles = [
    boardBaseStyles,
    css`
      button {
        padding: 0.25em;
        border-radius: 0.5em;
        border: none;
        width: 2em; height: 2em;
        cursor: pointer;
        background-color: var(--small-button--bgc, transparent);
        font-size: 1em;
      }
      button:hover {
        background-color: var(--small-button--bgch, #dddddd);
      }

      ::slotted(svg) {
        width: 1.5em; height: 1.5em;
      }
    `
  ];

  render() {
    return html`
      <button>
        <slot></slot>
      </button>
    `;
  }
}