export function selectAllText(element: HTMLElement) {
  // Creamos un rango de selección que abarque todo el contenido del elemento.
  const range = document.createRange();
  range.selectNodeContents(element);
  // Eliminamos cualquier rango de selección previo y añadimos el nuevo.
  const selection = window.getSelection();
  selection?.removeAllRanges();
  selection?.addRange(range);
}

export function formatText(
  editElement: HTMLElement,
  command: 'B' | 'I' | 'U' | 'S'
) {
  const selection = document.getSelection();

  // ¿Está la selección dentro del elemento editable? Si no está se sale.
  if (
    !selection?.anchorNode || !editElement.contains(selection.anchorNode)
  ) return;

  // ¿La selección está colapsada? Si es así marcamos el tipo de acción que se
  // quiere hacer 'bold' para que en event listener del teclado en el elemento
  // editable pueda luego llevar a cabo la acción al pulsar la siguiente tecla.
  if (selection.isCollapsed) return;


  const range = selection?.getRangeAt(0);
  if (!range) return;

  // ¿La selección está completamente dentro de un nodo <b>?
  // -------------------------------------------------------
  let ancestor = getAncestorOfType(range, command, editElement);
  // SI : Se elimina la etiqueta de la selección y se deja el que lo rodea.
  if (ancestor) unwrapRangeFromNode(range, ancestor as HTMLElement);
  // NO : Se añade la etiqueta.
  else wrapRangeInTypeOfNode(range, command);
}

export function getAncestorOfType(
  range: Range,
  type: string,
  limit: Node | null = null
) {
  let parent = range.commonAncestorContainer;
  while (parent && parent != limit && parent.nodeName !== type) {
    parent = parent.parentNode as Node;
  }
  if (parent.nodeName === type) return parent;
  else return null;
}

function unwrapRangeFromNode(range: Range, parentNode: HTMLElement) {
  // inserto un nodo marca al inicio y final del parentNode por si no hubiera
  // contenido antes o después de la selección.
  const startParent = document.createTextNode('\u200B');
  parentNode.insertBefore(startParent, parentNode.firstChild);
  const endParent = document.createTextNode('\u200B');
  parentNode.appendChild(endParent);

  // Se guarda el contenido original de la selección.
  const rangeContent = range.extractContents();

  const tmpRange = document.createRange();

  // Separa el contenido antes de la selección dentro del padre.
  tmpRange.setStartAfter(startParent);
  tmpRange.setEnd(range.startContainer, range.startOffset);
  const beforeContent = tmpRange.extractContents();
  // Separa el contenido después de la selección dentro del padre.
  tmpRange.setStart(range.endContainer, range.endOffset);
  tmpRange.setEndBefore(endParent);
  const afterContent = tmpRange.extractContents();

  // El nodo padre sigue existiendo, así que ponemos ahí beforeContent.
  parentNode.appendChild(beforeContent);
  // Se añade afterContent en un nuevo nodo del mismo tipo que el padre.
  const endParentNode = document.createElement(parentNode.nodeName);
  endParentNode.appendChild(afterContent);
  parentNode.after(endParentNode);
  // Y ahora en el medio de los dos añadimos el contenido seleccionado.
  parentNode.after(rangeContent);

  // Volvemos a seleccionar
  tmpRange.setStartAfter(parentNode);
  tmpRange.setEndBefore(endParentNode);
  document.getSelection()?.removeAllRanges();
  document.getSelection()?.addRange(tmpRange);

  startParent.remove();
  endParent.remove();
  // Si los nodos inicial y final quedaran vacíos se eliminan.
  parentNode.normalize();
  endParentNode.normalize();
  if (!parentNode.firstChild) parentNode.remove();
  if (!endParentNode.firstChild) endParentNode.remove();
}

function wrapRangeInTypeOfNode(range: Range, typeOfNode: string) {
  const content = range.extractContents();
  // Borramos los nodos del mismo tipo que el que se va a añadir que tengan los
  // nodos del contenido ya que no se pueden anidar.
  removeTypeOfNodeFromFragment(content, typeOfNode);

  // Se inserta el contenido en un nuevo nodo del tipo typeOfNode.
  const newNode = document.createElement(typeOfNode);
  newNode.appendChild(content);
  range.insertNode(newNode);
  newNode.normalize();

  // Si el nodo anterior es del mismo tipo que el que se acaba de añadir se
  // unen en uno solo.
  if (newNode.previousSibling?.nodeName === typeOfNode) {
    while (newNode.previousSibling.firstChild)
      newNode.insertBefore(newNode.previousSibling.firstChild, newNode.firstChild);
    newNode.previousSibling.remove();
  }
  // Si el nodo siguiente es del mismo tipo que el que se acaba de añadir se
  // unen en uno solo.
  if (newNode.nextSibling?.nodeName === typeOfNode) {
    while (newNode.nextSibling.firstChild)
      newNode.appendChild(newNode.nextSibling.firstChild);
    newNode.nextSibling.remove();
  }
  newNode.normalize();

  // Se selecciona todo el contenido dentro del nuevo nodo.
  range.selectNodeContents(newNode);
}

function removeTypeOfNodeFromFragment(
  node: Node,
  typeOfNode: String
) {
    if (node.hasChildNodes()) {
      node.childNodes.forEach(child => {
        if (child.nodeName === typeOfNode) {
          unwrapNode(child);
        }
        else if (child.hasChildNodes()) {
          removeTypeOfNodeFromFragment(child, typeOfNode);
        }
      });
    };
}

function unwrapNode(node: Node): void {
  const parent = node.parentNode;
  while (node.firstChild) {
    parent?.insertBefore(node.firstChild, node);
  }
  parent?.removeChild(node);
}

export function convertUrlToAnchor(text: string) {
  const pattern = /(https?:\/\/(?:www\.)?[\w\-\.]+\.[a-z]{2,})/gi;
  const replacement = '<a href="$1" target="_blank">$1</a>';
  return text.replace(pattern, replacement);
}