import { LitElement, html, css } from 'lit-element';
import { customElement, property, query } from 'lit/decorators.js';
import { boardBaseStyles } from './board-base-styles';

@customElement('title-section')
export class TitleSection extends LitElement {

  @property({ type: String }) declare title: string;

  @query('input') declare inputElement: HTMLInputElement;
  @query('#title') declare titleElement: HTMLDivElement;

  static styles = [
    boardBaseStyles,
    css`
      :host {
        flex-grow: 1;
        font-weight: bold;
        text-align: left;
        word-break: break-word;
      }

      input {
        font-weight: bold;
        font-size: 1em;
        margin: 0.5em 0;
        padding: 0;
        border: none;
        width: 100%;
        background-color: #f1f2f4;
      }
      input:focus {
        outline: none;
        border: none;
        background-color: #f1f2f4;
      }

      .hide {
        display: none;
      }
    `
  ]

  // Pointerup en lugar de pointerdown para evitar que se activen al arrastrar.
  render() {
    return html`
      <div id="title" @pointerup=${this.editTitle}>${this.title}</div>
      <input type="text" class="hide" @keydown=${this.handleKeydown}>
    `;
  }

  constructor() {
    super();
    this.closeEditTitle = this.closeEditTitle.bind(this);
  }

  private editTitle() {
    this.titleElement.classList.add('hide');
    this.inputElement.classList.remove('hide');
    this.dispatchEvent(new CustomEvent('editing'));
    // Esperamos a que se renderice input para poder seleccionar su contenido.
    setTimeout(() => {
      this.inputElement.value = this.title;
      this.inputElement.select();
      this.inputElement.focus();
    });
    // Para poder cancelar el formulario pulsando fuera de él añadimos un evento
    // en el body que lo oculte si se pulsa fuera.
    document.body.addEventListener(
      'pointerdown',
      this.closeEditTitle,
      { once: true }
    );
  }

  editedTitle() {
    if (this.inputElement.value) {
      this.title = this.inputElement.value;
      this.closeEditTitle();
    }
  }

  closeEditTitle() {
    this.titleElement.classList.remove('hide');
    this.inputElement.classList.add('hide');
    document.body.removeEventListener('pointerdown', this.closeEditTitle);
    const event = new CustomEvent('edited', { detail: { title: this.title } });
    this.dispatchEvent(event); 
  }
  
  handleKeydown(e: KeyboardEvent) {
    if (e.key === 'Enter') this.editedTitle();
    if (e.key === 'Escape') this.closeEditTitle();
  }
}