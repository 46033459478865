import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { clockIcon } from "./icons";

export type ClockType = 'minutes' | 'seconds' | 'clock';

@customElement("digital-clock")
export class DigitalClock extends LitElement {

  @property() declare timeString: string;
  @property({reflect: true}) declare clockType: ClockType;
  
  timer!: ReturnType<typeof setInterval>;

  static styles = css`
    div {
      cursor: pointer;
    }
    div:hover {
      background-color: rgba(0, 0, 0, 0.7);
    }
    small-button svg path{
      stroke: white;
    }
  `;

  render() {
    return html`
      <div @click=${() => this.changeFormat()}>
        ${this.clockType === 'clock' ?
          html`<small-button>${clockIcon}</small-button>`
          :
          this.timeString
        }
      </div>
    `;
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeTick();
  }

  // Cuando el atributo cambia configuramos el tick
  attributeChangedCallback(name: string, oldval: string, newval: string) {
    super.attributeChangedCallback(name, oldval, newval);

    if (name === 'clocktype') this.changeFormat(newval as ClockType);
  }

  tick() {
    if (this.clockType === 'minutes') {
      this.timeString = new Date().toLocaleTimeString(
        'es-ES',
        {hour: '2-digit', minute: '2-digit'}
      );
    }
    else if (this.clockType === 'seconds') {
      this.timeString = new Date().toLocaleTimeString(
        'es-ES',
        {hour: '2-digit', minute: '2-digit', second: '2-digit'}
      );
    }
    else this.removeTick();
  }

  changeFormat(newType?: ClockType) {
    if (newType) {
      this.clockType = newType;
      this.setTick();
    }
    else {
      if (this.clockType === 'clock') {
        this.clockType = 'minutes';
        this.setTick();
      }
      else if (this.clockType === 'minutes') {
        this.clockType = 'seconds';
        this.setTick();
      }
      else {
        this.clockType = 'clock';
      }

      this.dispatchEvent(new CustomEvent('change-type', {
        detail: this.clockType
      }));
    }
  }

  setTick() {
    this.removeTick(); // Por si ya hubiera el timer configurado
    this.timer = setInterval(() => this.tick(), 1000);
    this.tick();
  }

  removeTick() {
    clearInterval(this.timer);
  }
}