import { LitElement, css, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { boardBaseStyles } from "../board-base-styles";
import "./medium-button";

@customElement("confirm-dialog")
export class ConfirmDialog extends LitElement {

  @property({ type: String }) declare confirmText;
  @property({ type: String }) declare cancelText;

  static styles = [
    boardBaseStyles,
    css`
      .overlay {
        position: fixed;
        top: 0; left: 0; right: 0; bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .dialog {
        background: white;
        padding: 1.25em;
        border-radius: 0.5em;
        max-width: 30em;
      }

      #buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 1.25em;
      }
    `
  ];

  // tabindex="0" permite que el elemento reciba el foco si se hace click y que
  // pueda ser cancelado con la tecla Escape.
  render() {
    return html`
      <div
        id="overlay"
        class="overlay"
        @click=${this.cancel}
        @keydown=${this.handleKeydown}
        tabindex="0"
      >
        <div
          id="dialog"
          class="dialog"
          @click=${(e: Event) => e.stopPropagation()}
        >
          <slot></slot>
          <div id="buttons">
            <medium-button id="cancel" @click=${this.cancel} autofocus>
              ${this.cancelText}
            </medium-button>
            <medium-button id="confirm" @click=${this.confirm}>
              ${this.confirmText}
            </medium-button>
          </div>
        </div>
      </div>
    `;
  }

  constructor(
    message: string, confirm: string = "Confirmar", cancel: string = "Cancelar"
  ) {
    super();
    this.confirmText = confirm;
    this.cancelText = cancel;
    this.innerHTML = message;
  }

  cancel() {
    this.dispatchEvent(new CustomEvent("cancel"));
    this.remove();
  }

  confirm() {
    this.dispatchEvent(new CustomEvent("confirm"));
    this.remove();
  }

  handleKeydown(e: KeyboardEvent) {
    if (e.key === 'Escape') this.cancel();
  }
}