import { LitElement, css, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { boardBaseStyles } from "./board-base-styles";
import "./ui/small-button";
import "./title-section";

@customElement("board-config")
export class BoardConfig extends LitElement {

  @property({ type: Boolean }) declare isOpen: boolean;
  @property({ type: String }) declare backgroundColor: string;

  static styles = [
    boardBaseStyles,
    css`
      :host {
        display: flex;
        flex-direction: column;
        margin: 1em 0;
        width: 18em;
        flex-shrink: 0;
        overflow: hidden;
      }

      .color {
        border: none;
        width: 2em;
        height: 2em;
        cursor: pointer;
        // Color de fondo igual al valor del atributo data-color
        background-color: var(--color);

      }
      .brown { background-color: brown; }
      .coral { background-color: coral; }
      .darkgoldenrod { background-color: darkgoldenrod; }
      .darkolivegreen { background-color: darkolivegreen; }
      .royalblue { background-color: royalblue; }
      .darkslateblue { background-color: darkslateblue; }
      .rosybrown { background-color: rosybrown; }
      .dimgrey { background-color: dimgrey; }

      .color.selected {
        outline: 0.125em solid #333;
      }

      #top {
        display: flex;
        flex-shrink: 0;
        padding: 0;
        border-radius: 0.75em 0.75em 0 0;
        border-bottom: 0.0625em solid #ccc;
        min-height: 2em;
        background-color: #f1f2f4;
        align-items: center;
        cursor: pointer;
      }

      #menu-button {
        border: none;
        border-radius: 0.75em;
        width: 100%; height: 2.5em;
        font-size: 1em;
        background-color: #f1f2f4;
        cursor: pointer;
      }
      #menu-button:hover {
        background-color: #ddd;
      }

      #content {
        padding: 1em 0.5em 1em;
        border-radius: 0 0 0.75em 0.75em;
        background-color: #f1f2f4;
        overflow-y: auto;
      }
      #content::-webkit-scrollbar {
        width: 0.5em;
      }
      #content::-webkit-scrollbar-track {
        background: transparent
      }
      #content::-webkit-scrollbar-thumb {
        width: 0.25em;
        background: #aaa;
        border-radius: 0.5em;
      }
      #content::-webkit-scrollbar-thumb:hover {
        background: #999;
      }
    `
  ];

  render() {
    return html`
      ${this.isOpen ?
        html`
          <div id="top">
            <button id="menu-button" @click=${this.openMenu}>Cerrar configuración del tablero</button>
          </div>
          <div id="content">
            Color de fondo
            <div id="colors" @click=${this.changeColor}>
              <button
                class="color brown ${this.backgroundColor === "brown" ? "selected" : ""}"
                data-color="brown"
              ></button>
              <button
                class="color coral ${this.backgroundColor === "coral" ? "selected" : ""}"
                data-color="coral"
              ></button>
              <button
                class="color darkgoldenrod ${this.backgroundColor === "darkgoldenrod" ? "selected" : ""}"
                data-color="darkgoldenrod"
              ></button>
              <button
                class="color darkolivegreen ${this.backgroundColor === "darkolivegreen" ? "selected" : ""}"
                data-color="darkolivegreen"
              ></button>
              <button
                class="color royalblue ${this.backgroundColor === "royalblue" ? "selected" : ""}"
                data-color="royalblue"
              ></button>
              <button
                class="color darkslateblue ${this.backgroundColor === "darkslateblue" ? "selected" : ""}"
                data-color="darkslateblue"
              ></button>
              <button
                class="color rosybrown ${this.backgroundColor === "rosybrown" ? "selected" : ""}"
                data-color="rosybrown"
              ></button>
              <button
                class="color dimgrey ${this.backgroundColor === "dimgrey" ? "selected" : ""}"
                data-color="dimgrey"
              ></button>
            </div>
          </div>
        `
        :
        html`
          <button id="menu-button" @click=${this.openMenu}>Abrir configuración del tablero</button>
        `
      }
    `;    
  }

  constructor() {
    super();
    this.isOpen = false;
    this.backgroundColor = "brown";
  }

  openMenu() {
    this.isOpen = !this.isOpen;
  }

  changeColor(e: Event) {
    const color = (e.target as HTMLElement).dataset.color;
    if (color) {
      this.backgroundColor = color;
      this.dispatchEvent(new CustomEvent("change-color", { detail: color }));
    }
  }
}