export const LOCAL_STORAGE_QUOTA_IN_BYTES = 5242880; // 5 * 1024 * 1024 == 5MB
export const LOCAL_STORAGE_QUOTA_STRING = '5 MB';

// La API File System Access todavía es experimental, tiene un bajo soporte y
// podría cambiar en el futuro. No está soportada aún por typescript por lo que
// tenemos que declarar los tipos necesarios.
//
// TODO: se recomienda verificar como va avanzando el soporte de esta API.
//
// https://caniuse.com/native-filesystem-api
// https://developer.chrome.com/docs/capabilities/web-apis/file-system-access?hl=es-419

declare global {
  interface Window {
    showSaveFilePicker: any;
  }
}

type FileOptions = {
  id?: string;
  startIn?: 'documents' | 'desktop' | 'downloads' | 'pictures' | 'videos' | 'music';
  suggestedName?: string;
  types?: {
      description: string;
      accept: Record<string, string[]>;
    }[];
  type: 'application/json' | 'application/joriboard';
}

const JSON_FILES = {
  description: 'Ficheros JSON',
  accept: {'application/json': ['.json']},
};

const JORIBOARD_FILES = {
  description: 'Ficheros joriboard',
  accept: {'application/joriboard': ['.jori']},
};


export async function saveObjToCompressedJSONFile(
  obj: Object,
  fileName: string,
  id: string = 'joriboard'
) {
  const json = JSON.stringify(obj);
  const blob = await compressTextToBlob(json);
  const options: FileOptions = {
      id,
      startIn: 'downloads',
      suggestedName: fileName,
      types: [JORIBOARD_FILES],
      type: 'application/joriboard',
    };
    return saveBlobToFile(blob, options);
}

export function saveObjToJSONFile(
  obj: Object,
  fileName: string,
  id: string = 'joriboard'  // Para que el picker recuerde la ubicación.
) {
  const json = JSON.stringify(obj);
  const options: FileOptions = {
    id,
    startIn: 'downloads',
    suggestedName: fileName,
    types: [JSON_FILES],
    type: 'application/json',
  };
  const blob = new Blob([json], { type: options.type });
  return saveBlobToFile(blob, options);
}

async function compressTextToBlob(text: string) {
  // Convertir el texto a un flujo de bytes.
  const encoder = new TextEncoder();
  const bytes = encoder.encode(text);
  const compresionStream = new CompressionStream('gzip');
  // Pasar los bytes al stream de compresión.
  const stream = new Blob([bytes]).stream().pipeThrough(compresionStream);
  // Convertir el stream comprimido a un Blob y devolverlo.
  return await new Response(stream).blob();
}

export function decompressBlobToText(blob: Blob) {
  const stream = blob.stream().pipeThrough(new DecompressionStream("gzip"));
  return streamToString(stream);

}

async function streamToString(stream: ReadableStream) {
  const reader = stream.getReader();
  const decoder = new TextDecoder();
  let result = '';
  while (true) {
    const { done, value } = await reader.read();
    if (done) break;
    result += decoder.decode(value);
  }
  return result;
}

async function saveBlobToFile(blob: Blob, options: FileOptions) {
  try {
    // Miramos si el navegador soporta la API File System Access.
    if ('showSaveFilePicker' in window) {
      const handle = await window.showSaveFilePicker(options);
      const writable = await handle.createWritable();
      await writable.write(blob);
      await writable.close();
    }
    // Si no, guardamos el fichero de forma estándar.
    else {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = options.suggestedName || 'file.tmp';
      a.click();
      URL.revokeObjectURL(url);
    }
    return true;
  }
  catch (error) {
    return false;
  }
}

// Devuelve el tamaño de un string en bytes.
export function getStringSize(string: string): number {
  return new Blob([string]).size;
}

// Devuelve el número de bytes en un string legible.
export function getReadableSize(sizeInBytes: number): string {
  // Convierte a kilobytes (KB) y megabytes (MB)
  const sizeInKB = sizeInBytes / 1024;
  const sizeInMB = sizeInKB / 1024;

  // Devuelve el tamaño en un formato legible
  if (sizeInMB >= 1) {
    return `${sizeInMB.toFixed(2)} MB`;
  } else {
    return `${sizeInKB.toFixed(2)} KB`;
  }
}

// Espacio ocupado en bytes por el almacenamiento local.
export function getLocalStorageUsedSpace(): number {
  const keys = Object.keys(localStorage);
  let bytes = 0;
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    bytes += (localStorage.getItem(key)?.length || 0);
  }
  return bytes;
}

export function imageBlobToBase64(blob: Blob) {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
}

export function resizeAndCompressBase64Image(
  image: string,
  { width = 1920, height = 0, quality = 0.7 } = {}
) {
  return new Promise<string>((resolve) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    const img = new Image();
    img.onload = () => {
      // Si el ancho es mayor que el de la imagen, se mantiene el ancho.
      if (width > img.width) width = img.width;
      // Si la altura es cero, se calcula proporcionalmente.
      if (height === 0) height = img.height * width / img.width;
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);
      canvas.toBlob((blob) => {
        imageBlobToBase64(blob as Blob).then(resolve);
      }, 'image/jpeg', quality);
    };
    img.src = image;
  });
}
