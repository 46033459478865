// Ejemplo de uso
// const isoTime = "2023-10-01T12:00:00Z";
// console.log(timeAgo(isoTime));
export function timeAgo(isoTime: string): string {
  const now = new Date();
  const past = new Date(isoTime);
  const diff = now.getTime() - past.getTime();

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  // const years = Math.floor(days / 365);

  if (seconds < 60) {
    return `hace ${seconds} segundo${seconds == 1 ? '' : 's'}`;
  } else if (minutes < 60) {
    return `hace ${minutes} minuto${minutes == 1 ? '' : 's'}`;
  } else if (hours < 24) {
    return `hace ${hours} hora${hours == 1 ? '' : 's'}`;
  } else if (days < 365) {
    return `hace ${days} día${days == 1 ? '' : 's'}`;
  } else {
    return "hace mucho tiempo";
  }
}

