import { LitElement, html, css } from 'lit-element';
import { customElement, query } from 'lit/decorators.js';
import { boardBaseStyles } from './board-base-styles';

@customElement('add-section')
export class AddSection extends LitElement {

  @query('#show-button') declare showButtonElement: HTMLButtonElement;
  @query('#new-section') declare newSectionElement: HTMLDivElement;
  @query('input') declare inputElement: HTMLInputElement;

  static styles = [
    boardBaseStyles,
    css`
      button {
        border: none;
        border-radius: 0.75em;
        width: 100%; height: 2.5em;
        font-size: 1em;
        background-color: #f1f2f4;
        cursor: pointer;
      }
      button:hover {
        background-color: #ddd;
      }

      #new-section {
        margin: 0;
        padding: 1em 0.5em;
        border-radius: 0.75em;
        background-color: #f1f2f4;
        box-shadow: 0 0.0625em 0.125em rgba(0, 0, 0, 0.4);
        text-align: left;
      }

      #menu {
        display: flex;
        justify-content: space-between;
        margin: 1em 0 0;
        gap: 0.5em;
      }

      input {
        margin: 0;
        padding: 0.5em;
        border: none;
        width: 100%;
        font-weight: bold;
        font-size: 1em;
        background-color: #f1f2f4;
      }
      input:focus {
        outline: none;
        border: none;
      }

      .hide {
        display: none;
      }
    `
  ]

  render() {
    return html`
      <button id="show-button" @click=${this.showForm}>Añadir sección</button>
      <div
        id="new-section"
        class="hide"
        @pointerdown=${(e: PointerEvent) => e.stopPropagation()}
      >
        <input
          type="text"
          placeholder="Introduce el nombre de sección"
          @keydown=${this.handleKeydown}
        >
        <div id="menu">
          <button @click=${this.hideForm}>Cancelar</button>
          <button @click=${this.addSection}>Añadir</button>
        </div>
      </div>
    `;
  }

  constructor() {
    super();
    this.hideForm = this.hideForm.bind(this);
  }

  showForm() {
    this.showButtonElement.classList.add('hide');
    this.newSectionElement.classList.remove('hide');
    this.inputElement.focus();
    // Para poder cancelar el formulario pulsando fuera de él añadimos un evento
    // en el body que lo oculte si se pulsa fuera.
    document.body.addEventListener(
      'pointerdown',
      this.hideForm,
      { once: true }
    );
  }

  hideForm() {
    this.showButtonElement.classList.remove('hide');
    this.newSectionElement.classList.add('hide');
    document.body.removeEventListener('pointerdown', this.hideForm);
  }

  addSection() {
    if (this.inputElement.value) {
      this.dispatchEvent(new CustomEvent('add-section', {
        detail: { title: this.inputElement.value }
      }));
      this.inputElement.value = '';
      this.hideForm();
    }
  }

  handleKeydown(e: KeyboardEvent) {
    if (e.key === 'Enter') this.addSection();
    if (e.key === 'Escape') this.hideForm();
  }
}