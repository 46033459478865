import { LitElement, css, html } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { boardBaseStyles } from "./board-base-styles";
import { closeIcon, enlargeIcon, reduceIcon } from "./icons";
import { BaseCard } from "./cards/base-card";
import { TextCard } from "./cards/text-card";
import { ImageCard } from "./cards/image-card";
import "./ui/small-button";
import "./title-section";
import { AddCard } from "./add-card";
import { confirmDialog } from "./ui/dialogs";
import { BoardSectionData, sizeSection } from '../types/board';

@customElement("board-section")
export class BoardSection extends LitElement {

  // Si se arrastra una sección (solo se puede una a la vez) la guardamos para
  // poder soltarla en otra sección y poder actuar en consecuencia.
  static draggedSection: BoardSection | null = null;
  // Se compara con la posición del ratón para saber si hay que insertar la
  // sección que se arrastra antes o después de la sección en la que acaba de
  // entrar el ratón,
  static initialDragPosition: number = 0;

  @property({ type: String }) declare title: string;
  @property({ type: String, reflect: true }) declare size: sizeSection;

  @query('#content') declare contentElement: HTMLDivElement;
  @query('#menu') declare menuElement: HTMLElement;
  @query('#board-section') declare boardSectionElement: HTMLElement;
  @query('#new-card-button') declare newCardButton: HTMLButtonElement;

  static styles = [
    boardBaseStyles,
    css`
      :host {
        margin: 0 0.5em;
        width: 18em;
        flex-shrink: 0;
        overflow: hidden;
      }
      :host([size="medium"]) {
        width: 36em;
      }
      :host([size="large"]) {
        width: 54em;
      }
      :host(.animatable) {
        transition: transform 0.4s ease, margin 0.4s ease;
      }
      :host(.shrink) {
        margin: -9em;
        transform: scale(0);
      }

      #board-section {
        display: flex;
        flex-direction: column;
        border-radius: 0.75em;
        max-height: 100%;
        box-shadow: 0 0.0625em 0.125em rgba(0, 0, 0, 0.4);
      }

      #top {
        display: flex;
        flex-shrink: 0;
        padding: 1em 1em 0;
        border-radius: 0.75em 0.75em 0 0;
        min-height: 2em;
        background-color: #f1f2f4;
        align-items: center;
        cursor: pointer;
      }

      #content {
        padding: 0 0.5em;
        background-color: #f1f2f4;
        overflow-y: auto;
      }
      #content::-webkit-scrollbar {
        width: 0.5em;
      }
      #content::-webkit-scrollbar-track {
        background: transparent
      }
      #content::-webkit-scrollbar-thumb {
        width: 0.25em;
        background: #aaa;
        border-radius: 0.5em;
      }
      #content::-webkit-scrollbar-thumb:hover {
        background: #999;
      }

      #bottom {
        flex-shrink: 0;
        padding: 0 0.5em;
        border-radius: 0 0 0.75em 0.75em;
        min-height: 0.5em;
        background-color: #f1f2f4;
      }

      #new-card-button {
        border: none;
        border-radius: 0.75em;
        margin: 0 0 0.5em;
        padding: 0 0 0 0.5em;
        width: 100%;
        height: 2.5em;
        font-size: 1em;
        font-weight: bold;
        color: #444;
        background-color: #f1f2f4;
        text-align: left;
        cursor: pointer;
      }
      #new-card-button:hover {
        background-color: #ddd;
      }

      .hide {
        display: none;
      }
    `
  ];

  render() {
    return html`
      <li
        id="board-section"
        class="shrink"
        @pointerdown=${this.setDraggableAttribute}
        @pointerup=${this.removeDraggableAttribute}
        @pointerleave=${this.removeDraggableAttribute}
      >
        <div id="top">
          <title-section
            title=${this.title}
            @editing=${this.editingTitle}
            @edited=${this.editedTitle}
          ></title-section>
          <span id="menu">
          ${this.size === 'large' ?
            html`
              <small-button @click=${this.resizeSection}>
                ${reduceIcon}
              </small-button>
            `
            :
            html`
              <small-button @click=${this.resizeSection}>
                ${enlargeIcon}
              </small-button>
            `
          }
            <small-button @click=${this.removeSection}>${closeIcon}</small-button>
          </span>
        </div>
        <div id="content">
          <slot></slot>
        </div>
        <div id="bottom">
          <button id="new-card-button" @click=${this.addCard}>
            + Nueva tarjeta
          </button> 
        </div>
      </li>
    `;    
  }

  constructor(title: string) {
    super();
    this.id = 's-' + crypto.randomUUID();
    this.title = title;
    this.size = 'small';
  }

  protected firstUpdated() {
    this.setupDragAndDrop();
  }

  private addCard() {
    const addCard = new AddCard();
    this.appendChild(addCard);
    this.newCardButton.classList.add('hide');
    setTimeout(() => {
      this.contentElement.scrollTo(0, this.contentElement.scrollHeight);
    });
    addCard.addEventListener('new-card', ((e: CustomEvent) => {
      addCard.remove();
      this.newCardButton.classList.remove('hide');
      this.newCardButton.focus();
      // Miramos el tipo de evento para saber si se ha añadido una tarjeta o no.
      switch (e.detail.type) {
        case 'text': {
          const newCard = new TextCard(e.detail.title);
          this.appendChild(newCard);
          // Se emite evento para que el tablero guarde el estado.
          this.dispatchEvent(new CustomEvent('save-board', { bubbles: true }));
          break;
        }
        case 'image': {
          const newCard = new ImageCard(e.detail.image);
          this.appendChild(newCard);
          // Se emite evento para que el tablero guarde el estado.
          this.dispatchEvent(new CustomEvent('save-board', { bubbles: true }));
          break;
        }
      }
    }) as EventListener, { once: true });
  }

  private async resizeSection() {
    switch (this.size) {
      case 'small':
        this.size = 'medium';
        break;
      case 'medium':
        this.size = 'large';
        break;
      case 'large':
        this.size = 'small';
        break;
    }
    await this.updateComplete;
    // Evitamos que quede fuera de la pantalla al cambiar su tamaño.
    this.scrollIntoView({ behavior: 'smooth', inline: 'center' });
    // Se emite evento para que el tablero guarde el estado.
    this.dispatchEvent(new CustomEvent('save-board', { bubbles: true }));
  }

  private async removeSection() {
    const cerrar: boolean = await confirmDialog(
      '¿Está seguro de que quiere eliminar la sección y todo lo que hay dentro?'
    );

    if (cerrar) {
      // Las secciones solo queremos que sean animables al añadirlas al tablero
      // ya que si no se produciría animcación también al hacer zoom.
      this.classList.add('animatable');
      this.classList.add('shrink'); // Animación de reducción.
      this.addEventListener('transitionend', () => {
        // Se emite evento para que el tablero guarde el estado.
        this.dispatchEvent(new CustomEvent('save-board', { bubbles: true }));
        this.remove();
      }, { once: true });
    }
  }

  private editingTitle() {
    this.menuElement.classList.add('hide');
  }

  private editedTitle(e: CustomEvent) {
    this.title = e.detail.title;
    this.menuElement.classList.remove('hide');
    // Se emite evento para que el tablero guarde el estado.
    this.dispatchEvent(new CustomEvent('save-board', { bubbles: true }));
  }

  public serialize() {
    let cardsElements = Array.from(this.children) as BaseCard[];
    cardsElements = cardsElements.filter((card) => card instanceof BaseCard);
    const cards = cardsElements.map((card) => card.serialize());
    return {
      id: this.id,
      title: this.title,
      size: this.size,
      cards,
    };
  }

  // Llamado por board-canvas.ts para cargar el estado del tablero en la función
  // deserialize (llamada al importar un tablero o al recargar la página).
  static load(data: BoardSectionData) {
    const section = new BoardSection(data.title);
    section.id = data.id;
    section.size = data.size || 'small';
    data.cards.forEach((cardData: any) => {
      let card: BaseCard;
      if (cardData.type === 'text') card = TextCard.load(cardData);
      else if (cardData.type === 'image') card = ImageCard.load(cardData);
      else card = BaseCard.load(cardData);
      section.appendChild(card);
    });
    return section
  }

  setDraggableAttribute() {
    // Firefox no permite seleccionar texto en un elemento editable si un padre
    // es draggable. Por eso solo añadimos el atributo cuando vayamos a
    // arrastrar la sección para evitar este problema en las tarjetas hijas.
    this.boardSectionElement.setAttribute('draggable', 'true');
  }

  removeDraggableAttribute() {
    // Si no estamos intentando arrastrar eliminamos el atributo draggable para
    // que firefox permita seleccionar texto en las tarjetas hijas editables.
    this.boardSectionElement.removeAttribute('draggable');
  }

  private setupDragAndDrop() {
    // Evita que el evento se propague al padre y este lo cancele
    // (preventDefault) permitiendo así el drag and drop. Solo dejamos pasar
    // el evento si se hace click con el botón central del ratón para que se
    // pueda arrastrar el tablero.
    const section = this.boardSectionElement;
    section.addEventListener('pointerdown', (e) => {
      if (e.button !== 1) e.stopPropagation();
    });

    this.addEventListener('dragstart', (e) => {
      // Evita a un padre ser arrastrado si estamos arrastarando una sección.
      e.stopPropagation();
      e.dataTransfer?.clearData();
      // El tipo de datos (1º parámetro) siempre se convierte a minúsculas.
      e.dataTransfer?.setData('boardsection', this.id);
      this.style.opacity = '0.4';
      BoardSection.draggedSection = this;
      BoardSection.initialDragPosition = e.pageX;
      // Necesario para que no se muestre el icono de prohibido al arrastrar.
      document.addEventListener('dragover', dragoverCallback);
    });

    this.addEventListener('dragend', () => {
      this.style.opacity = '1';
      BoardSection.draggedSection = null;
      document.removeEventListener('dragover', dragoverCallback);
      // Se emite evento para que el tablero guarde el estado.
      this.dispatchEvent(new CustomEvent('save-board', { bubbles: true }));
    });

    function dragoverCallback(e: DragEvent) {
      e.preventDefault();
    } 

    this.addEventListener('dragenter', (e) => {
      switch (e.dataTransfer?.types[0]) {
        // Si se está arrastrando una sección actuamos en consecuencia.
        case 'boardsection':
          const section = BoardSection.draggedSection;
          if (section === null) return;
          // Si el ratón se está moviendo a la izquierda, insertamos antes.
          if (e.pageX < BoardSection.initialDragPosition) {
            this.parentElement!.insertBefore(section, this);
          } else {
            // Si el ratón se está moviendo a la izquierda, insertamos antes.
            this.parentElement!.insertBefore(section, this.nextSibling);
          }
          // Actualizamos la posición inicial para que la siguiente comparación
          // sea a partir de la posición actual del ratón.
          BoardSection.initialDragPosition = e.pageX;
          break;
        // Si se está arrastrando una tarjeta actuamos en consecuencia.
        case 'basecard':
          const card = BaseCard.draggedCard;
          if (card === null) return;
          // Insertamos la tarjeta al final de la sección.
          this.appendChild(card);
          break;
      }
    });
  }
}