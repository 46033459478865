import { PropertyValues, css, html, render } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import { editIcon, minusCircleIcon, plusCircleIcon } from "../icons";
import "../ui/small-button";
import { BaseCard } from "./base-card";
import DOMPurify from 'dompurify';
import {
  boldIcon, italicIcon, strikethroughIcon, underlineIcon
} from '../icons';
import {
  selectAllText, formatText, convertUrlToAnchor
} from '../../utils/editor-utils';

@customElement("text-card")
export class TextCard extends BaseCard {

  @query('#content') declare contentDiv: HTMLDivElement;
  @query('#format-menu') declare formatMenuElement: HTMLDivElement;
  get editDiv() { return this.querySelector('#edit') as HTMLDivElement }


  @property({ type: String }) declare title: string;
  @property({ type: String }) declare titleEdit: string;
  @property({ type: Boolean, reflect: true }) declare editing: boolean;
  @property({ type: Number }) declare textSize: number;

  static styles = [
    ...BaseCard.styles,
    css`
      :host([editing]) {
        outline: 1px solid #333;
        background-color: #eee;
      }

      ::slotted(#edit) {
        cursor: text;
        word-break: break-word;
        white-space: pre-wrap;
        font-size: calc(1em + var(--text-size, 0) * 0.125em);
      }
      ::slotted(#edit:focus) {
        outline: none;
        border: none;
      }
      ::slotted(#edit:empty):before {
        content: attr(data-placeholder);
        color: #666;
      }


      #content {
        word-break: break-word;
        white-space: pre-wrap;
        font-size: calc(1em + var(--text-size, 0) * 0.125em);
      }

      #format-menu {
        position: fixed;
        top: 0;
        left: 0;
        display: none;
        gap: 0.5em;
        margin: 0.5em 0 0;
        border-radius: 0.5em;
        font-size: 0.75em;
        background-color: #fff;
        z-index: 1;
        box-shadow: 0 0.0625em 0.125em rgba(0, 0, 0, 0.4);
        font-size: 0.875em;
      }
      #format-menu small-button {
        --small-button--bgc: white;
      }
    `
  ];

  render() {
    return html`
      <div id="format-menu">
        <small-button @click=${this.bold}>${boldIcon}</small-button>
        <small-button @click=${this.italic}>${italicIcon}</small-button>
        <small-button @click=${this.underline}>${underlineIcon}</small-button>
        <small-button @click=${this.strikethrough}>${strikethroughIcon}</small-button>
      </div>
      ${this.editing ?
        html`
          <slot
            id="edit"
            @keydown=${this.handleKeydown}
            @click=${(e: Event) => e.stopPropagation()}
            @pointerdown=${(e: PointerEvent) => e.stopPropagation()}
            @dragstart=${(e: DragEvent) => e.stopPropagation()}
            @pointerup=${(e: PointerEvent) => e.stopPropagation()}
          >${unsafeHTML(DOMPurify.sanitize(this.titleEdit))}</slot>
        `
        // En firefox no se puede seleccionar el texto si un elemento padre es
        // draggable. Por eso se añade el atributo siempre justo antes de que
        // se vaya a arrastrar el elemento y se quita al terminar.
        : html`
          <div
            id="content"
            @pointerdown=${this.setDraggableAttribute}
            @pointerup=${this.removeDraggableAttribute}
            @pointerleave=${this.removeDraggableAttribute}
          >${unsafeHTML(convertUrlToAnchor(DOMPurify.sanitize(this.title)))}</div>
          ${this.renderMenu(html`
            <small-button @click=${this.decreaseTextSize}>
              ${minusCircleIcon}
            </small-button>
            <small-button @click=${this.increaseTextSize}>
              ${plusCircleIcon}
            </small-button>
            <small-button @click=${this.editTitle}>
              ${editIcon}
            </small-button>
          `)}
        `
      }
    `;    
  }

  editContentTemplate() {
    return html`
      <div
        id="edit"
        data-placeholder="Introduce un título para la tarjeta"
        contenteditable="true"
      >${unsafeHTML(DOMPurify.sanitize(this.titleEdit))}</div>
    `;
  }

  constructor(title: string) {
    super();
    this.title = DOMPurify.sanitize(title);
    this.titleEdit = title;
    // Base 0.875em. Cada nivel aumenta o disminuye 0.125em === 2px normalmente.
    this.textSize = 0;
    this.editing = false;
    // Se bindean las funciones que serán usadas en los listeners.
    this.editTitle = this.editTitle.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);
    this.close = this.close.bind(this);
    this.handleSelectionChange = this.handleSelectionChange.bind(this);
  }

  protected updated(_changedProperties: PropertyValues): void {
    if (_changedProperties.has('textSize')) {
      this.style.setProperty('--text-size', this.textSize.toString());
    }
  }

  private increaseTextSize() {
    this.textSize = Math.min(12, this.textSize + 1);
    this.dispatchEvent(new CustomEvent('save-board', { bubbles: true }));
  }

  private decreaseTextSize() {
    this.textSize = Math.max(-2, this.textSize - 1);
    this.dispatchEvent(new CustomEvent('save-board', { bubbles: true }));
  }

  async editTitle() {
    this.editing = true;
    this.titleEdit = this.title;
    // Si se cancela la edición, aunque hayamos escrito algo, la siguienet línea
    // no va a poner el texto igual a this.title porque en realidad
    // this.titleEdit no fue actualizado ya que this.title no cambió. Eso hace
    // que Lit vea que this.titleEdit no cambió y no actualice el contenido del
    // slot. Si queremos que se actualice deberíasmos escribir antes otro valor.
    // ¿Deberíamos hacerlo o no? ¿Queremos que cuando se cancele (muchas veces
    // por accidente) la edición, al volver a darle a editar tengamos lo que
    // dejamos a medias? (Es un buen método para que el usuario no pierda lo que
    // escribió por error).
    render(this.editContentTemplate(), this);
    await this.updateComplete;

    selectAllText(this.editDiv);
    // Para poder cancelar el formulario pulsando fuera de él. Se pone en un
    // evento de click que será llamado por el click que llevó a esta función.
    // Si no se hiciera así se cerraría inmediatamente.
    addEventListener('click', () => {
      addEventListener('click', this.close, { once: true });
      document.addEventListener('selectionchange', this.handleSelectionChange);
    }, { once: true });
  }

  async acceptTitle(e: KeyboardEvent) {
    e.preventDefault(); // Evita que añada un salto de línea al título.
    // Tenemos que mirar el contenido antes de cambiar las clases o se perderán
    // los cambios realizados en el input.
    const title = DOMPurify.sanitize(this.editDiv.innerHTML.trim());
    if (title) {
      this.title = title;
      await this.close();
      this.dispatchEvent(new CustomEvent('save-board', { bubbles: true }));
    }
  }

  async close() {
    this.formatMenuElement.style.display = 'none';
    this.editing = false;
    // this.setAttribute('draggable', 'true');
    await this.updateComplete;
    // Se elimina el listener para cerrar el formulario y aceptar los cambios.
    removeEventListener('click', this.close);
    document.removeEventListener('selectionchange', this.handleSelectionChange);
  }

  handleKeydown(e: KeyboardEvent) {
    if (e.key === 'Enter' && !e.shiftKey) this.acceptTitle(e);
    if (e.key === 'Escape') this.close();
  }

  public serialize() {
    return {
      ...super.serialize(),
      type: 'text',
      title: this.title,
      textSize: this.textSize,
    };
  }

  static load(data: any) {
    const card = new TextCard(data.title);
    card.id = data.id;
    card.textSize = data.textSize || 0;
    return card;
  }

  setDraggableAttribute() {
    // Firefox no permite seleccionar texto en un elemento editable si un padre
    // es draggable. Por eso solo añadimos el atributo cuando vayamos a
    // arrastrar la sección para evitar este problema en las tarjetas hijas.
    this.setAttribute('draggable', 'true');
  }

  removeDraggableAttribute() {
    // Si no estamos intentando arrastrar eliminamos el atributo draggable para
    // que firefox permita seleccionar texto en las tarjetas hijas editables.
    this.removeAttribute('draggable');
  }

  handleSelectionChange() {
    const selection = window.getSelection();
    if (!selection) return;
    const range = selection.getRangeAt(0);
    if (range.collapsed) {
      this.formatMenuElement.style.display = 'none';
    }
    else {
      this.formatMenuElement.style.display = 'inline-block';
      // Se mira la posición de la selección.
      const rect = range.getBoundingClientRect();
      // Se coloca el menú en la posición de la selección.
      this.formatMenuElement.style.top = `calc(${rect.top}px - 3em)`;	
      this.formatMenuElement.style.left = `${rect.left}px`;
    }
  }

  bold(e: Event) {
    e.stopPropagation(); // Evita que se cancele el elemento.
    formatText(this, 'B');
  }

  italic(e: Event) {
    e.stopPropagation(); // Evita que se cancele el elemento.
    formatText(this, 'I');
  }
  underline(e: Event) {
    e.stopPropagation(); // Evita que se cancele el elemento.
    formatText(this, 'U');
  }
  strikethrough(e: Event) {
    e.stopPropagation(); // Evita que se cancele el elemento.
    formatText(this, 'S');
  }

  stopPropagation(e: Event) {
    e.stopPropagation();
  }
}