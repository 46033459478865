import { LitElement, css, html } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { boardBaseStyles } from './board-base-styles';
import './add-section';
import {
  getReadableSize, LOCAL_STORAGE_QUOTA_IN_BYTES
} from '../utils/file-utils';
import { timeAgo } from '../utils/time-utils';
import { selectAllText } from '../utils/editor-utils';


@customElement('board-header')
export class BoardHeader extends LitElement {

  @property({ type: Number }) declare version: number;
  @property({ type: String }) declare id: string;
  get idLast() { return this.id.split('-').at(-1); }
  @property({ type: String }) declare title: string;
  @property({ type: Number, attribute: 'board-size' }) declare boardSize: number;
  @property({ type: Boolean, attribute: 'has-been-exported' }) declare hasBeenExported: boolean;
  @property({ type: String, attribute: 'last-update' }) declare lastUpdate: string;
  @property({ type: String }) declare titleEdit: string;
  @property({ type: Boolean }) declare editing: boolean;

  @query('#edit-title') declare editTitleDiv: HTMLDivElement;

  static styles = [
    boardBaseStyles,
    css`
      header {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: 1.5em 2em;
        grid-template-areas: 
          "logo menu"
          "title title";
        font-size: 1.4em;
        color: white;
        background-color: rgba(0, 0, 0, 0.5);
      }

      #header-logo {
        grid-area: logo;
        display: flex;
        flex-direction: row;
        padding-left: 1em;
        font-weight: bold;
        justify-content: left;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);
        padding-top: 0.25em 0;
      }
      #header-title {
        grid-area: title;
        display: flex;
        flex-direction: row;
        padding: 0 1em;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
      }
      #header-id, .info {
        margin-left: 1em;
        font-size: 0.5em;
        color: #aaa;
      }
      #header-menu {
        grid-area: menu;
        display: flex;
        padding-right: 1em;
        justify-content: right;
        align-items: center;
        font-weight: bold;
        font-size: 0.75em;
        background-color: rgba(0, 0, 0, 0.5);
        gap: 0.5em;
      }

      button {
        border: none;
        border-radius: 0.5em;
        padding: 0.5em;
        font-size: 1em;
        background-color: transparent;
        color: white;
        cursor: pointer;
      }
      button:hover {
        background-color: rgba(0, 0, 0, 0.7);
      }

      #quota-exceeded {
        display: inline-block;
        padding: 0 0.5em;
        margin-right: 0.5em;
        background-color: #ddd;
        color: #a00;
        font-weight: bold;
        border-radius: 0.5em;        
      }

      @media (max-width: 560px) {
        header {
          grid-template-columns: auto;
          grid-template-rows: 1.5em 2.5em 1.5em;
          grid-template-areas: 
            "logo"
            "title"
            "menu";
        }

        #header-logo {
          justify-content: center;
        }

        #header-menu {
          justify-content: center;
        }
      }
    `
  ];

  render() {
    return html`
      <header>
        <div id="header-menu">
          <button
            @click=${() => this.dispatchEvent(new CustomEvent('new-board'))}
          >
            Nuevo
          </button>
          <button
            @click=${() => this.dispatchEvent(new CustomEvent('export-board'))}
          >
            Exportar
          </button>
          <button
            @click=${() => this.dispatchEvent(new CustomEvent('import-board'))}
          >
            Importar
          </button>
        </div>
        <div id="header-logo">
          JORIBOARD
          <span class="info">ID:${this.idLast} v${this.version}</span>
        </div>
        <div id="header-title">
          ${this.editing ?
            html`
              <div
                id="edit-title"
                contenteditable="true"
                @keydown=${this.handleKeydownEditTitle}
              >
                ${this.titleEdit}
              </div>
            `
            : html`
              <div @click=${this.editTitle}>
                ${this.title || 'Tablero sin título'}
              </div>
            `
          }
          <div id="header-id">
            ${LOCAL_STORAGE_QUOTA_IN_BYTES < this.boardSize ?
              html`
                <span id="quota-exceeded">
                  ${this.hasBeenExported ? '' : '*'}
                  ${getReadableSize(this.boardSize)} / 5 MB -
                  Cuota local excedida
                </span>
              `
              :
              html`
                ${this.hasBeenExported ? '' : '*'}
                ${getReadableSize(this.boardSize)} / 5 MB -
                💾 ${timeAgo(this.lastUpdate)}
              `
            }
          </div>
        </div>
      </header>
    `;
  }

  constructor() {
    super();
    this.titleEdit = '';
    this.editing = false;

    this.closeEditTitle = this.closeEditTitle.bind(this);
  }

  async editTitle(e: Event) {
    e.stopPropagation();
    this.titleEdit = this.title;
    this.editing = true;
    await this.updateComplete;
    selectAllText(this.editTitleDiv);
    // Para poder cancelar el formulario pulsando fuera de él.
    document.body.addEventListener('click', this.closeEditTitle, { once: true });
  }

  async closeEditTitle() {
    this.editing = false;
    await this.updateComplete;
    this.title = this.titleEdit;
    document.body.removeEventListener('click', this.closeEditTitle);
    this.dispatchEvent(new CustomEvent('title-updated', { detail: this.title }));
  }

  async acceptTitle(e: KeyboardEvent) {
    e.preventDefault(); // Evita que añada un salto de línea al título.
    const text = this.editTitleDiv.textContent?.trim();
    // Si no hay texto no se deja aceptar el título.
    if (text) {
      this.titleEdit = text;
      this.closeEditTitle();
    }
  }

  handleKeydownEditTitle(e: KeyboardEvent) {
    if (e.key === 'Enter' && !e.shiftKey) this.acceptTitle(e);
    if (e.key === 'Escape') this.closeEditTitle();
  }
}
