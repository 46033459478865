import { LitElement, html, css, render } from 'lit-element';
import { customElement, query } from 'lit/decorators.js';
import { boardBaseStyles } from './board-base-styles';
import { imageBlobToBase64, resizeAndCompressBase64Image } from '../utils/file-utils';
import { boldIcon, italicIcon, strikethroughIcon, underlineIcon } from './icons';
import { formatText } from '../utils/editor-utils';

@customElement('add-card')
export class AddCard extends LitElement {

  @query('#format-menu') declare formatMenuElement: HTMLDivElement;
  private newCard!: HTMLDivElement;

  static styles = [
    boardBaseStyles,
    css`
      ::slotted(#new-card) {
        position: relative;
        display: block;
        margin: 0em 0 0.5em;
        padding: 1em 0.5em;
        border-radius: 0.5em;
        background-color: #fff;
        box-shadow: 0 0.0625em 0.125em rgba(0, 0, 0, 0.4);
        text-align: left;
        font-size: 0.875em;
        cursor: cursor;
        word-break: break-word;
        white-space: pre-wrap;
      }
      ::slotted(#new-card:focus) {
        outline: none;
        border: none;
      }
      ::slotted(#new-card[contenteditable="true"]:empty):before {
        content: attr(data-placeholder);
        color: #666;
      }

      #format-menu {
        position: fixed;
        top: 0;
        left: 0;
        display: none;
        gap: 0.5em;
        margin: 0.5em 0 0;
        border-radius: 0.5em;
        font-size: 0.75em;
        background-color: #fff;
        z-index: 1;
        box-shadow: 0 0.0625em 0.125em rgba(0, 0, 0, 0.4);
      }

      #menu {
        display: flex;
        justify-content: space-between;
        margin: 1em 0 1em;
        gap: 0.5em;
      }

      button {
        border: none;
        border-radius: 0.75em;
        width: 100%; height: 2.5em;
        font-size: 1em;
        background-color: #f1f2f4;
        cursor: pointer;
      }
      button:hover {
        background-color: #ddd;
      }
    `
  ]

  render() {
    return html`
      <div id="format-menu">
        <small-button @click=${this.bold}>${boldIcon}</small-button>
        <small-button @click=${this.italic}>${italicIcon}</small-button>
        <small-button @click=${this.underline}>${underlineIcon}</small-button>
        <small-button @click=${this.strikethrough}>${strikethroughIcon}</small-button>
      </div>
      <slot
        @keydown=${this.handleKeydown}
        @pointerdown=${this.stopPropagation}
        @click=${this.stopPropagation}
        @paste=${this.handlePaste}
      ></slot>
      <div id="menu">
        <button @click=${this.close}>Cancelar</button>
        <button @click=${this.addCard}>Añadir</button>
      </div>
    `;
  }

  newCardTemplate = html`
    <div
      id="new-card"
      data-placeholder="Introduce un título o pega una imagen."
      contenteditable="true"
    ></div>
  `;

  constructor() {
    super();
    this.close = this.close.bind(this);
    this.handleSelectionChange = this.handleSelectionChange.bind(this);
  }

  firstUpdated() {
    // Creamos un div hijo editable que será el que reciba el foco.
    // Lo creamos como hijo para poder utilizar el window.getSelection() que
    // no funciona bien dentro de un shadowDOM y con distintas soluciones según
    // el navegador.
    render(this.newCardTemplate, this);
    this.newCard = this.querySelector('#new-card') as HTMLDivElement;
    this.newCard.focus();
    
    document.addEventListener('selectionchange', this.handleSelectionChange);

    // Para evitar que el evento cierre el formulario inmediatamente si el
    // custom element actual fue creado durante un evento de click, esperamos
    // al siguiente frame.
    requestAnimationFrame(() => {
      // Para poder cancelar el formulario pulsando fuera de él.
      document.body.addEventListener('click', this.close, { once: true });
    });
  }

  // Borramos los eventos cuando el elemento salga del DOM.
  disconnectedCallback() {
    document.body.removeEventListener('click', this.close);
    document.removeEventListener('selectionchange', this.handleSelectionChange);
    super.disconnectedCallback();
  }

  close() {
    this.dispatchEvent(new CustomEvent('new-card', {
      detail: { title: '', type: 'cancel' }
    }));
  }

  addCard(e: KeyboardEvent) {
    e.preventDefault(); // Evita que añada un salto de línea al título.
    const title = this.newCard.innerHTML?.trim();
    if (!title) return; // Si no hay título, no se añade la tarjeta.
    this.dispatchEvent(new CustomEvent('new-card', {
      detail: { title, type: 'text' }
    }));
  }

  handleKeydown(e: KeyboardEvent) {
    // Evitar que se exporte el tablero ya que se generaría un error durante el
    // serializado de la tarjeta ficticia que tenemos ahora y evitamos también
    // que salga el menú de guardado estándar del navegador.
    if (e.ctrlKey && e.key === 's') {
      e.stopPropagation();
      e.preventDefault();
    }
    if (e.key === 'Enter' && !e.shiftKey) this.addCard(e);
    if (e.key === 'Escape') this.close();
  }

  async handlePaste(e: ClipboardEvent) {
    e.preventDefault();
    // Detectar si se ha pegado una imagen y en ese caso se crea la tarjeta con
    // esa imagen cerrando el formulario.
    if (e.clipboardData?.types.includes('Files')) {
      const file = e.clipboardData?.files[0];
      if (file && file.type.startsWith('image/')) {
        const base64Image = await resizeAndCompressBase64Image(
          await imageBlobToBase64(file), {}
        );
        this.dispatchEvent(new CustomEvent('new-card', {
          detail: { image: base64Image, type: 'image' }
        }));
      }
    }
    // Si se recibo texto se añade a la posición del cursor.
    else {
      const text = e.clipboardData?.getData('text');
      if (!text) return; // Si no hay texto no se hace nada.
      const selection = window.getSelection();
      if (selection) {
        const range = selection.getRangeAt(0);
        range.deleteContents();
        // range.insertNode(document.createTextNode(text));
        const div = document.createElement('div');
        div.innerText = text;
        range.insertNode(div);
      }
    }
  }

  handleSelectionChange() {
    const selection = window.getSelection();
    if (!selection) return;
    const range = selection.getRangeAt(0);
    if (range.collapsed) {
      this.formatMenuElement.style.display = 'none';
    }
    else {
      this.formatMenuElement.style.display = 'inline-block';
      // Se mira la posición de la selección.
      const rect = range.getBoundingClientRect();
      // Se coloca el menú en la posición de la selección.
      this.formatMenuElement.style.top = `calc(${rect.top}px - 3em)`;	
      this.formatMenuElement.style.left = `${rect.left}px`;
    }
  }

  bold(e: Event) {
    e.stopPropagation(); // Evita que se cancele el elemento.
    formatText(this, 'B');
  }

  italic(e: Event) {
    e.stopPropagation(); // Evita que se cancele el elemento.
    formatText(this, 'I');
  }
  underline(e: Event) {
    e.stopPropagation(); // Evita que se cancele el elemento.
    formatText(this, 'U');
  }
  strikethrough(e: Event) {
    e.stopPropagation(); // Evita que se cancele el elemento.
    formatText(this, 'S');
  }

  stopPropagation(e: Event) {
    e.stopPropagation();
  }
}
