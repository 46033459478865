import { LitElement, css, html } from "lit";
import { customElement, query } from "lit/decorators.js";
import { boardBaseStyles } from "../board-base-styles";

@customElement("medium-button")
export class MediumButton extends LitElement {

  @query('button') declare button: HTMLButtonElement;

  static styles = [
    boardBaseStyles,
    css`
      button {
        padding: 0.25em;
        border-radius: 0.5em;
        border: none;
        width: auto; height: 2em;
        cursor: pointer;
        background-color: var(--small-button--bgc, transparent);
        font-size: 1em;
      }
      button:hover {
        background-color: #dddddd;
      }

      ::slotted(svg) {
        width: 1.5em; height: 1.5em;
      }
    `
  ];

  render() {
    return html`
      <button>
        <slot></slot>
      </button>
    `;
  }

  protected firstUpdated() {
    if (this.autofocus) this.button.focus();
  }
}