import { MessageDialog } from "./message-dialog";
import { ConfirmDialog } from "./confirm-dialog";
import { ImageDialog } from "./image-dialog";

export function imageDialog(image: string): Promise<void> {
  return new Promise((resolve) => {
    const dialog = new ImageDialog(image);
    document.body.appendChild(dialog);

    const onCancel = () => {
      resolve();
      cleanup();
    };

    const cleanup = () => {
      dialog.removeEventListener('cancel', onCancel);
      dialog.remove();
    };

    dialog.addEventListener('cancel', onCancel);
  });
}

export function messageDialog(message: string): Promise<void> {
  return new Promise((resolve) => {
    const dialog = new MessageDialog(message);
    document.body.appendChild(dialog);

    const onCancel = () => {
      resolve();
      cleanup();
    };

    const cleanup = () => {
      dialog.removeEventListener('cancel', onCancel);
      dialog.remove();
    };

    dialog.addEventListener('cancel', onCancel);
  });
}

export function confirmDialog(
  message: string, confirm?: string, cancel?: string
): Promise<boolean> {
  return new Promise((resolve) => {
    const dialog = new ConfirmDialog(message, confirm, cancel);
    document.body.appendChild(dialog);

    const onConfirm = () => {
      resolve(true);
      cleanup();
    };

    const onCancel = () => {
      resolve(false);
      cleanup();
    };

    const cleanup = () => {
      dialog.removeEventListener('confirm', onConfirm);
      dialog.removeEventListener('cancel', onCancel);
      dialog.remove();
    };

    dialog.addEventListener('confirm', onConfirm);
    dialog.addEventListener('cancel', onCancel);
  });
}