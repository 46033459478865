import { css, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import "../ui/small-button";
import { BaseCard } from "./base-card";
import { getReadableSize, getStringSize } from "../../utils/file-utils";
import { imageDialog } from "../ui/dialogs";

@customElement("image-card")
export class ImageCard extends BaseCard {

  @property({ type: String }) declare base64Image: string;
  @property({ type: String }) declare imageSize: string;

  static styles = [
    ...BaseCard.styles,
    css`
      :host {
        padding: 0;
      }
      img {
        display: block;
        border-radius: 0.5em;
        margin: 0;
        padding: 0;
        width: 100%;
        height: auto;
      }
      #size {
        display: flex;
        place-content: center;
        padding: 0 1em;
        margin-top: 0.5em;
      }
    `
  ];

  render() {
    return html`
      <img
        src=${this.base64Image}
        @click=${this.showImage}
        @contextmenu=${(e: Event) => e.stopPropagation()}
        alt="Imagen de la tarjeta"
      >
      ${this.renderMenu(html`
        <div id="size">${this.imageSize}</div>
      `)}
    `;    
  }

  constructor(base64Image: string) {
    super();
    this.base64Image = base64Image;
    this.imageSize = getReadableSize(getStringSize(base64Image));
  }

  showImage() {
    imageDialog(this.base64Image);
  }
    
  public serialize() {
    return {
      ...super.serialize(),
      type: 'image',
      base64Image: this.base64Image,
    };
  }

  static load(data: any) {
    const card = new ImageCard(data.base64Image);
    card.id = data.id;
    return card;
  }
}