import { LitElement, css, html } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { BoardSection } from './board-section';
import { boardBaseStyles } from './board-base-styles';
import './add-section';
import './board-header';
import './board-config';
import { BoardData } from '../types/board';
import {
  saveObjToCompressedJSONFile, getStringSize, decompressBlobToText,
  LOCAL_STORAGE_QUOTA_STRING, LOCAL_STORAGE_QUOTA_IN_BYTES
} from '../utils/file-utils';
import { confirmDialog, messageDialog } from "./ui/dialogs";
import {
  extendWindowIcon, minusCircleIcon, plusCircleIcon, restoreWindowIcon
} from './icons';
import { ClockType } from './digital-clock';

const VERSION = 9;

@customElement('board-canvas')
export class BoardCanvas extends LitElement {

  @property({ type: String }) declare title: string;
  @property({ type: Boolean, reflect: true }) declare extended: boolean;

  @query('#board') declare boardElement: HTMLOListElement;

  @property({ type: Number }) declare version: number;
  @property({ type: String }) declare id: string;
  get idLast() { return this.id.split('-').at(-1); }
  @property({ type: String }) declare lastUpdate: string;
  @property({ type: Number }) declare boardSize: number;
  @property({ type: Boolean }) declare hasBeenExported: boolean;
  @property({ type: Number }) declare zoom: number;
  private clockType: ClockType;
  private backgroundColor: string;

  private sheduledSave: number = 0;
  private showSaveError: boolean = true;

  static styles = [
    boardBaseStyles,
    css`
      :host {
        --zoom: 0;
        --background-color: brown;

        display: flex;
        flex-direction: column;
        padding: 0;
        width: 100%; height: 100%;
        background-color: var(--background-color);
        text-align: center;
      }

      :host([extended]) ::slotted(board-section) {
        height: fit-content !important;
      }

      #board {
        font-size: calc(1em * (1 + (0.1 * var(--zoom))));
        display: flex;
        padding: 1em 1em 0.5em; margin: 0;
        width: 100%; height: 100%;
        background-color: var(--background-color);
        overflow: auto;
        flex-wrap: nowrap;
        list-style-type: none;
      }

      #board::-webkit-scrollbar {
        height: 0.5em;
      }
      #board::-webkit-scrollbar-track {
        background: transparent;
      }
      #board::-webkit-scrollbar-thumb {
        background: #ddd;
        border-radius: 0.5em;
      }
      #board::-webkit-scrollbar-thumb:hover {
        background: #999;
      }

      .board-section {
        margin: 0 0.5em;
        width: 18em;
        flex-shrink: 0;
        overflow: hidden;
      }

      button {
        border: none;
        border-radius: 0.5em;
        padding: 0.5em;
        font-size: 1em;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        cursor: pointer;
      }
      button:hover {
        background-color: rgba(0, 0, 0, 0.7);
      }

      #footer-menu {
        position: fixed;
        display: flex;
        align-items: center;
        right: 1em;
        bottom: 1em;
        border-radius: 0.5em;
        padding: 0 0.5em;
        color: white;
        background-color: rgba(0, 0, 0, 0.5);
        --small-button--bgch: rgba(0, 0, 0, 0.5);
      }
      #zoom {
        padding: 0.25em;
        width: 3.5em;
        background-color: transparent;
      }
      #zoom:hover {
        background-color: rgba(0, 0, 0, 0.7);
      }
      #zoom-in {
        margin-right: 1em;
      }
      digital-clock {
        margin-right: 1em;
      }
      small-button svg path{
        stroke: white;
      }
    `
  ];

  render() {
    return html`
      <board-header
        id=${this.id}
        version=${this.version}
        title=${this.title}
        board-size="${this.boardSize}"
        ?has-been-exported=${this.hasBeenExported}
        last-update=${this.lastUpdate}
        @new-board=${this.newBoard}
        @export-board=${this.export}
        @import-board=${this.import}
        @title-updated=${this.titleUpdated}
      ></board-header>
      <ol id="board" @wheel=${this.handleWheel}>
        <slot></slot>
        <li class="board-section">
          <add-section @add-section=${this.addSection}></add-section>
          <board-config
            backgroundcolor=${this.backgroundColor}
            @change-color=${this.changeColor}
          ></board-config>
        </li>
      </ol>

      <div id="footer-menu">
        <small-button @click=${this.zoomOut}>
          ${minusCircleIcon}
        </small-button>
        <button id="zoom" @click=${this.resetZoom}>
          ${100 + 10 * this.zoom} %
        </button>
        <small-button id="zoom-in" @click=${this.zoomIn}>
          ${plusCircleIcon}
        </small-button>
        <digital-clock
          clocktype=${this.clockType}
          @change-type=${this.changeClockType}
        ></digital-clock>
        ${this.extended ?
          html`
            <small-button @click=${this.changeWindowHeight}>
              ${restoreWindowIcon}
            </small-button>
          `
          :
          html`
            <small-button @click=${this.changeWindowHeight}>
              ${extendWindowIcon}
            </small-button>
          `
        }
      </div>
    `;
  }

  constructor() {
    super();
    this.id = 'b-' + crypto.randomUUID();
    this.version = VERSION;
    this.title = '';
    this.lastUpdate = new Date().toISOString();
    this.boardSize = 0;
    this.clockType = 'clock';
    this.backgroundColor = 'brown';

    this.zoom = 0;

    this.handleKeydownBoard = this.handleKeydownBoard.bind(this);
  }

  async firstUpdated() {
    this.setupDragAndDrop();

    document.addEventListener('keydown', this.handleKeydownBoard);
    this.addEventListener('save-board', () => this.save());
    window.addEventListener('beforeunload', (e) => {
      if (
        this.boardSize > LOCAL_STORAGE_QUOTA_IN_BYTES && !this.hasBeenExported
      ) {
        messageDialog(
          "El tablero no se ha guardado en el navegador por exceder la cuota " +
          "de almacenamiento local de " + LOCAL_STORAGE_QUOTA_STRING + ". " +
          "<br><br>" +
          "Si lo desea puede <b>exportar</b> el tablero guardándolo en su " +
          "ordenador y recuperarlo en el futuro importándolo.");
        e.preventDefault();
      }
    });

    await this.updateComplete;

    this.load();
  }

  titleUpdated(e: CustomEvent) {
    if (e.detail === this.title) return;
    this.title = e.detail;
    this.setWebTitle();
    this.save();
  }

  handleKeydownBoard(e: KeyboardEvent) {
    if (e.ctrlKey && e.key === 's') {
      e.preventDefault();
      this.export();
    }
  }

  zoomIn() {
    this.zoom++;
    if (this.zoom > 20) this.zoom = 20;
    this.boardElement.style.setProperty('--zoom', (this.zoom.toString()));
  }

  zoomOut() {
    this.zoom--;
    if (this.zoom < -9) this.zoom = -9;
    this.boardElement.style.setProperty('--zoom', (this.zoom.toString()));
  }
  
  resetZoom() {
    this.zoom = 0;
    this.boardElement.style.setProperty('--zoom', (this.zoom.toString()));
  }

  handleWheel(e: WheelEvent) {
    if (e.ctrlKey && e.deltaY > 0) {
      e.preventDefault();
      this.zoomOut();
    }
    else if (e.ctrlKey && e.deltaY < 0) {
      e.preventDefault();
      this.zoomIn();
    }
  }

  changeClockType(e: CustomEvent) {
    this.clockType = e.detail;
    this.save();
  }

  private addSection(e: CustomEvent) {
    // Añade una nueva sección al tablero pero minimizada.
    const newSection = new BoardSection(e.detail.title);
    // Las secciones solo queremos que sean animables al añadirlas al tablero ya
    // que si no se produciría animcación también al hacer zoom.
    newSection.classList.add('animatable');
    newSection.classList.add('shrink');
    this.appendChild(newSection);
    this.save(); // Guarda el tablero en localStorage.
    // Animación de crecimiento de la nueva sección. Para que se lance esperamos
    // al siguiente frame y eliminamos la clase que la minimiza.
    setTimeout(() => {
      newSection?.classList.remove('shrink');
      // Esperamos a que termine la animación para hacer scroll al elemento.
      newSection?.addEventListener('transitionend', () => {
        newSection.classList.remove('animatable');
        newSection?.scrollIntoView({ behavior: 'smooth', inline: 'center' });
      }, { once: true });
    });
  }

  serialize() {
    const sectionsElements: BoardSection[] = Array.from(
      this.querySelectorAll('board-section')
    );
    const sections = sectionsElements.map((section) => section.serialize());
    return {
      id: this.id,
      title: this.title,
      version: VERSION,
      extended: this.extended,
      lastUpdate: this.lastUpdate,
      hasBeenExported: this.hasBeenExported,
      clockType: this.clockType,
      backgroundColor: this.backgroundColor,
      sections,
    }
  }

  deserialize(board: string) {
    // Eliminaremos todas las secciones actuales.
    Array.from(this.children).forEach((section) => section.remove());
    // Ahora ya podemos cargar las secciones del fichero.
    const data: BoardData = JSON.parse(board);
    this.id = data.id;
    this.version = data.version;
    this.title = data.title || '';
    this.extended = data.extended || false;
    this.lastUpdate = data.lastUpdate || '';
    this.hasBeenExported = data.hasBeenExported || false;
    this.clockType = data.clockType || 'minutes';
    this.backgroundColor = data.backgroundColor || 'brown';
    this.style.setProperty('--background-color', this.backgroundColor);
    this.boardSize = getStringSize(board);
    for (const sectionData of data.sections) {
      const newSection = BoardSection.load(sectionData);
      this.appendChild(newSection);
    }
    this.setWebTitle();
  }

  save(updateSaveTime = true) {
    if (updateSaveTime) this.hasBeenExported = false;
    // Evita que se guarden varias veces seguidas en un corto espacio de tiempo.
    clearTimeout(this.sheduledSave);
    // Como puede haber sido llamado por un elemento hijo que se va a eliminar
    // esperamos un poco para que le de tiempo a hacerlo o el elemento seguirá
    // en el tablero al guardarse.
    this.sheduledSave = window.setTimeout(async () => {

      const startTime = performance.now();

      if (updateSaveTime) this.lastUpdate = new Date().toISOString();
      const board = JSON.stringify(this.serialize());
      this.boardSize = getStringSize(board);

      try {
        localStorage.setItem("joriboard", board);
        const endTime = performance.now();
        console.log('Tablero guardado en', (endTime - startTime).toFixed(2), 'ms');
      } catch (e) {
        const endTime = performance.now();
        if (this.showSaveError) {
          this.showSaveError = !await confirmDialog(
            "ERROR AL GUARDAR EL TABLERO: CUOTA EXCEDIDA" +
            "<br><br>" +
            "El tablero no se ha podido guardar localmente al exceder la " +
            "cuota de almacenamiento de su navegador de <b>" +
            LOCAL_STORAGE_QUOTA_STRING + "</b>. Si sale de la página o " +
            "cierra el navegador perderá los cambios realizados." +
            "<br><br>" +
            "Puede <b>exportar</b> el tablero para guardarlo en su ordenador " +
            "así poder recuperarlo más tarde importándolo.",
            "No volver a mostrar en esta sesión",
            "Cerrar mensaje"
          );
        }
        console.log('Tablero no guardado en', (endTime - startTime).toFixed(2), 'ms');
      }

    }, 1000);
  }

  load() {
    // Evita que apareza el asterisco de no exportado al cargar el tablero al
    // lado del tamaño del tablero.
    this.hasBeenExported = true;
    const board = localStorage.getItem("joriboard");
    if (board) {
      this.deserialize(board);
      this.requestUpdate();
    }
  }

  async export() {
    const board = this.serialize();
    const name = `${this.title || this.idLast}.v${this.version}.jori`;
    const OK = await saveObjToCompressedJSONFile(board, name);
    if (OK) {
      this.hasBeenExported = true;
      this.save(false); // Evita el asterisco de no exportado al recargar.
    }
  }

  import() {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".jori";
    input.onchange = (e) => {
      const target = e.target as HTMLInputElement;
      if (!target.files || !target.files[0]) return;

      const file = target.files[0];
      decompressBlobToText(file).then(async (board) => {
        // Hacemos un backup temporal del tablero por si tenemos se debe
        // recuperar si el usuario decide no sobreescribir el tablero actual.
        // Utilizamos esto en vez de coger una versión guardada en localStorage
        // porque es posible que se haya superado antes la cuota y no se haya
        // podido guardar.
        const boardBackup = this.serialize();
        const currentId = this.id;
        this.deserialize(board);
        if (this.id == currentId) {
          const shouldOverwrite: boolean = await confirmDialog(
            'Ya existe un tablero con el mismo ID guardado en el ' +
            'navegador. ¿Quiere sobrescribirlo?' +
            '<br><br>' +
            'Se sustituirán los datos guardados por los del fichero importado.',
            'Sobreescribir tablero',
            'Cancelar la carga'
          );
          // Guarda tablero en localStorage sin actualizar la fecha de guardado.
          if (shouldOverwrite) {
            this.save(false);
            // Al cargar un tablero importado se marca como exportado para que
            // no se muestre el asterisco en la fecha de guardado.
            this.hasBeenExported = true;
          }
          // Recupera el tablero anterior.
          else this.deserialize(JSON.stringify(boardBackup));
        }
        // Guarda tablero en localStorage sin actualizar la fecha de guardado.
        else {
          this.save(false);
          // Al cargar un tablero importado se marca como exportado para que
          // no se muestre el asterisco en la fecha de guardado.
          this.hasBeenExported = true;
        }
      })
      .catch(() => {
        messageDialog(
          "ERROR: ARCHIVO NO VÁLIDO" +
          "<br><br>" +
          "El fichero que está intentnando importar no es un tablero " +
          "válido de Joriboard."
        );
      });
    };
    input.click();
  }

  async newBoard() {
    const shouldReset: boolean = await confirmDialog(
      '¿Está seguro de que quiere crear un tablero nuevo? ' +
      'Se perderán los datos actuales.',
      'Crear tablero nuevo',
      'Cancelar'
    );
    if (shouldReset) {
      // Eliminaremos todas las secciones actuales.
      Array.from(this.children).forEach((section) => section.remove());
      // Creamos un nuevo tablero con un nuevo ID.
      this.id = 'b-' + crypto.randomUUID();
      this.version = VERSION;
      this.title = '';
      this.lastUpdate = new Date().toISOString();
      this.clockType = 'minutes';
      this.setWebTitle();
    }
  }

  setWebTitle() {
    document.title = (this.title || 'Tablero sin título') + ' - joriboard.com';
  }

  changeWindowHeight() {
    // si existe el atributo extended lo eliminamos, si no lo añadimos.
    this.extended = !this.extended;
    this.save();
  }

  changeColor(e: CustomEvent) {
    this.backgroundColor = e.detail;
    this.style.setProperty('--background-color', e.detail);
    this.save();
  }

  private setupDragAndDrop() {
    const board: HTMLOListElement = this.boardElement;
    // Al pulsar el ratón se empieza a gestionar el drag and drop.
    board.addEventListener('pointerdown', pointerdownCallback);
    // Evita el menú contextual en el tablero.
    board.addEventListener('contextmenu', (e) => { e.preventDefault(); });

    // Obtiene la posición inicial del ratón que luego usará el evento de mover
    // el ratón (añadimos quí) para mover el scroll a la nueva posición.
    // Añade también evento de soltar ratón que termina con el drag and drop.
    function pointerdownCallback(e: PointerEvent) {
      // Evita SELECCIONES y otros efectos que puedan interferir con el scroll.
      e.preventDefault();
      // No usar screenX, no se ajusta al zoom del navegador.
      let initialScrollPositionX: number = board.scrollLeft + e.pageX;
      let initialScrollPositionY: number = board.scrollTop + e.pageY;
      board.style.cursor = 'grabbing';
      // Los eventos se añaden al window para evitar problemas con el scroll y
      // el ratón saliendo del tablero.
      window.addEventListener('pointermove', pointermoveCallback);
      window.addEventListener('pointerup', pointerupCallback, { once: true });
      // Para dispositivos táctiles ya que si movemos el dedo después de pulsar
      // el evento pointerup no se lanza.
      window.addEventListener('touchend', pointerupCallback, { once: true });

      // Mueve el tablero en función de la posición del ratón al moverlo.
      function pointermoveCallback(e: PointerEvent) {
        // Evita selecciones y eventos que puedan interferir con el scroll.
        e.preventDefault();
        board.scrollLeft = initialScrollPositionX - e.pageX;
        board.scrollTop = initialScrollPositionY - e.pageY;
      }
      // Termina el drag and drop restaurando el cursor y eliminando eventos.
      function pointerupCallback() {
        e.preventDefault();
        board.style.cursor = 'default'; // Deja de mostrar el cursor grabbing.
        window.removeEventListener('pointermove', pointermoveCallback);
        window.removeEventListener('pointerup', pointerupCallback);
        window.removeEventListener('touchend', pointerupCallback);
      }
    }
  }
}
