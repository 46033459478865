import { LitElement, css, html } from "lit";
import { customElement, query } from "lit/decorators.js";
import { boardBaseStyles } from "../board-base-styles";
import { closeIcon } from "../icons";
import "./small-button";

@customElement("message-dialog")
export class MessageDialog extends LitElement {

  @query("#overlay") declare overlayElement: HTMLElement;

  static styles = [
    boardBaseStyles,
    css`
      .overlay {
        position: fixed;
        top: 0; left: 0; right: 0; bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .dialog {
        position: relative;
        background: white;
        padding: 1.25em;
        border-radius: 0.5em;
        max-width: 30em;
      }

      small-button {
        position: absolute;
        top: 0em;
        right: 0em;
      }
    `
  ];

  // tabindex="0" permite que el elemento reciba el foco si se hace click y que
  // pueda ser cancelado con la tecla Escape.
  render() {
    return html`
      <div
        id="overlay"
        class="overlay"
        @click=${this.cancel}
        @keydown=${this.handleKeydown}
        tabindex="0"
      >
        <div
          id="dialog"
          class="dialog"
          @click=${(e: Event) => e.stopPropagation()}
        >
          <slot></slot>
          <small-button @click=${this.cancel}>${closeIcon}</small-button>
        </div>
      </div>
    `;
  }

  constructor(message: string) {
    super();
    this.innerHTML = message;
  }

  protected firstUpdated() {
    // Para que pueda recibir eventos del teclado y pueda cancelar con Escape.
    this.overlayElement.focus();
  }

  cancel() {
    this.dispatchEvent(new CustomEvent("cancel"));
    this.remove();
  }

  handleKeydown(e: KeyboardEvent) {
    if (e.key === 'Escape') this.cancel();
  }
}