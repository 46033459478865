import { css } from 'lit';

// Todos los componentes de board-canvas fueron diseñado con un tamaño de
// fuente base de 1rem = 16px y se adaptarán en su tamaño según el valor
// de rem. Si se necesita que el tamaño del board-canvas sea diferente,
//  se puede cambiar el valor de la variable --board--base-size.
export const boardBaseStyles = css`
  * {
    box-sizing: border-box;
  }
  :host {
    font-size: var(--board-base-size, 1em);
    font-family: 'Roboto', 'Arial', 'Helvetica', 'Liberation Sans', sans-serif;
    line-height: 1.4;
  }
`;