import { LitElement, TemplateResult, css, html } from "lit";
import { customElement } from "lit/decorators.js";
import { boardBaseStyles } from "../board-base-styles";
import { closeIcon } from "../icons";
import "../ui/small-button";
import { confirmDialog } from "../ui/dialogs";

@customElement("base-card")
export class BaseCard extends LitElement {

  // Si se arrastra una sección (solo se puede una a la vez) la guardamos para
  // poder soltarla en otra sección y poder actuar en consecuencia.
  static draggedCard: BaseCard | null = null;

  static styles = [
    boardBaseStyles,
    css`
      :host {
        --text-size: 0;
        position: relative;
        display: block;
        margin: 0.5em 0;
        padding: 1em 0.5em;
        border-radius: 0.5em;
        background-color: #fff;
        box-shadow: 0 0.0625em 0.125em rgba(0, 0, 0, 0.4);
        text-align: left;
        font-size: 0.875em;	
        cursor: pointer;
      }

      #menu {
        position: absolute;
        top: 0.5em; right: 0.5em;
        display: none;
        border-radius: 0.5em;
        background-color: #eeeeee;
      }
      :host(:hover) #menu {
        display: flex;
      }
      .hide {
        display: none !important;
      }

      small-button {
        --small-button--bgc: #eeeeee;
      }
    `
  ];

  renderMenu(extraButtons?: TemplateResult) {
    return html`
      <div id="menu">
        ${extraButtons ? extraButtons : ''}
        <small-button @click=${this.removeCard}>${closeIcon}</small-button>
      </div>
    `;    
  }

  render() {
    return html`${this.renderMenu()}`;    
  }

  constructor() {
    super();
    // Inicializamos las propiedades de la tarjeta.
    this.id = 'c-' + crypto.randomUUID();
  }

  protected firstUpdated() {
    this.setupDragAndDrop();
  }

  async removeCard() {
    const cerrar: boolean = await confirmDialog(
      '¿Está seguro de que quiere eliminar la tarjeta?'
    );

    if (cerrar) {
      // Se emite evento para que el tablero guarde el estado.
      this.dispatchEvent(new CustomEvent('save-board', { bubbles: true }));
      this.remove();
    }
  }

  public serialize() {
    return {
      id: this.id,
      type: 'base',
    };
  }

  static load(data: any) {
    const card = new BaseCard();
    card.id = data.id;
    return card;
  }

  private setupDragAndDrop() {
    // Evita que el evento se propague al padre y este lo cancele
    // (preventDefault) permitiendo así el drag and drop. Solo se permite pasar
    // si se pulsa el botón central del ratón para que se pueda arrastrar el
    // tablero.
    this.addEventListener('pointerdown', (e) => {
      if (e.button !== 1) e.stopPropagation();
    });

    const menu: HTMLDivElement = this.renderRoot.querySelector('#menu')!;

    this.addEventListener('dragstart', (e) => {
      // Evita que se arrastre la sección a la que pertenece la tarjeta.
      e.stopPropagation();
      e.dataTransfer?.clearData();
      // El tipo de datos (1º parámetro) siempre se convierte a minúsculas.
      e.dataTransfer?.setData('basecard', this.id);
      this.style.opacity = '0.4';
      menu.classList.add('hide');
      BaseCard.draggedCard = this;
      // Necesario para que no se muestre el icono de prohibido al arrastrar.
      document.addEventListener('dragover', dragoverCallback);
    });

    this.addEventListener('dragend', () => {
      this.style.opacity = '1';
      menu.classList.remove('hide');
      BaseCard.draggedCard = this;
      document.removeEventListener('dragover', dragoverCallback);
    });

    function dragoverCallback(e: DragEvent) {
      e.preventDefault();
    }

    this.addEventListener('dragenter', (e) => {
      e.stopPropagation();
      switch (e.dataTransfer?.types[0]) {
        // Si se está arrastrando una sección la insertamos justo antes.
        case 'basecard':
          const card = BaseCard.draggedCard;
          if (card === null) return;
          this.parentElement!.insertBefore(card, this);
          break;
      }
    });
  }
}